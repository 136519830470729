import React from "react";

import { useNavigate, useSearchParams } from "react-router-dom";

import { useReservation } from "@features/reservation/reservation.queries";
import { NavLink } from "@fremtind/jkl-core";
import { ToggleSlider } from "@fremtind/jkl-toggle-switch-react";

import { brukerHarRolle, brukerHarTilgang, isRadgiver } from "../../common/rolle";
import { useBruker } from "../../features/bruker/queries";
import { BrukerRolle } from "../../model/bruker.typer";
import { Typography } from "../Typography";
import "./brandheaderbase.scss";

function getUrlEnv() {
    const hostParams = ["fremtind-test-app", "devaws.dnbforsikring.no"];
    //https://forsikring-bm-radgiver-at.app.devaws.dnbforsikring.no/bedrift/hjem
    //https://forsikring-bm-radgiver-at.app.fremtind-test-app.devaws.sparebank1.no/bedrift/hjem

    if (
        window.location.hostname.includes(`forsikring-bm-radgiver-at`) &&
        hostParams.some((param) => window.location.hostname.includes(param))
    ) {
        return "forsikring-bmkundesok-at.test.";
    } else if (/(localhost.|.test.|.devaws.)/g.test(window.location.hostname)) {
        return "forsikring-bmkundesok-01.test.";
    }

    if (/.qa./g.test(window.location.hostname)) {
        return "bmkundesok.qa.";
    }

    return "bmkundesok.intern.";
}

const KundesokLenke = ({ orgnummer }: { orgnummer?: string }) => {
    // https://forsikring-bmkundesok-01.test.fremtind.no/
    const url = new URL(`https://${getUrlEnv()}fremtind.no`);

    if (orgnummer) {
        url.searchParams.set("orgNr", orgnummer);
    }

    return (
        <NavLink back href={url.toString()}>
            Tilbake til Kundesøk BM
        </NavLink>
    );
};

const useIsHoppError = () => {
    const { error } = useBruker();
    const [searchParams] = useSearchParams();

    if (error && error.status === 404 && error.body.clientErrorId?.startsWith("forsikring.bm.radgiver.hopp")) {
        return true;
    }

    const feilmelding = searchParams.get("fId");

    return feilmelding?.startsWith("forsikring.bm.radgiver.hopp");
};

interface Props {
    logo: React.ReactNode;
}

const BrandHeaderBase = ({ logo }: Props) => {
    const { bruker, valgtOrganisasjon } = useBruker();
    const samtykkeQuery = useReservation();
    const navigate = useNavigate();

    const isHoppError = useIsHoppError();

    const samtykke = samtykkeQuery.data?.samtykke;

    const endreKonsesjon = () => {
        navigate("/bytt-konsesjon", { replace: true });
    };

    return (
        <div className="brand-header">
            <div className="brand-header__left">
                {(isRadgiver(bruker) || isHoppError) && (
                    <KundesokLenke orgnummer={valgtOrganisasjon?.organisasjonsnummer} />
                )}
            </div>
            <div
                className="brand-header__center"
                style={{ minWidth: isRadgiver(bruker) || isHoppError ? "20%" : "100%" }}
            >
                {logo}
            </div>
            <div className="brand-header__right">
                {/* bruk eksplisitt tilgangssjekk ettersom DNB ikke skal se konsesjonsbyttet */}
                {bruker &&
                    brukerHarRolle(bruker, [BrukerRolle.RADGIVER, BrukerRolle.RADGIVER_KUNDETILGANGER]) &&
                    samtykke &&
                    !samtykke.delingAvData?.kundeSamtykkeGitt && (
                        <>
                            <Typography>Velg visning</Typography>
                            <div className="min-w-[180px]">
                                <ToggleSlider
                                    defaultValue={
                                        brukerHarTilgang(bruker, BrukerRolle.RADGIVER_KONSESJON_LIV) ? "Liv" : "Skade"
                                    }
                                    labels={["Skade", "Liv"]}
                                    onToggle={() => endreKonsesjon()}
                                >
                                    {" "}
                                </ToggleSlider>
                            </div>
                        </>
                    )}
            </div>
        </div>
    );
};

export { BrandHeaderBase };
